import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DocumentTitle from "components/documentTitle";
import Header from "components/header";
import Link from "components/link";
import Wrapper from "components/wrapper";
import { useContact } from "data/contact";
import Phone from "icons/Phone";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

const UnhandledError: React.FC = () => {
  const [t] = useTranslation();
  const getContact = useContact();
  const contact = getContact();
  return (
    <Wrapper in={true} timeout={750}>
      <DocumentTitle title={t("app.errors.unhandledError.title")}>
        <Header title={t("app.errors.unhandledError.header")} />
        <Grid container component="main">
          <Grid
            item
            xs={12}
            sx={(theme) => ({
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(2),
              marginLeft: theme.spacing(1),
              marginRight: theme.spacing(1),
            })}
          >
            <Typography variant="h6" component="h2" data-testid="error-message">
              {t("app.errors.unhandledError.message")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={(theme) => ({
              marginLeft: theme.spacing(2),
              marginRight: theme.spacing(2),
              display: "flex",
              flexDirection: "row",

              "& .MuiTypography-body1": {
                marginLeft: "1rem",
              },
            })}
          >
            <Phone titleAccess={t("aria.phoneIcon")} />
            <Typography variant="body1" data-testid="contact-message">
              <Trans i18nKey="app.errors.unhandledError.contact" values={contact}>
                Please try again at a later time or contact us at{" "}
                <Link
                  sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                  href={`tel:${contact.phone}`}
                  color="secondary"
                  variant="inherit"
                  data-testid="contact-phonenumber"
                >
                  1-800-472-5625
                </Link>
              </Trans>
            </Typography>
          </Grid>
        </Grid>
      </DocumentTitle>
    </Wrapper>
  );
};

export default UnhandledError;
