import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Phone: React.FC<SvgIconProps> = ({ titleAccess, ...props }) => {
  return (
    <SvgIcon {...props} aria-label={titleAccess} titleAccess={titleAccess}>
      <path d="M5 0C4.44772 0 4 0.447716 4 1V23C4 23.5523 4.44772 24 5 24H19C19.5523 24 20 23.5523 20 23V1C20 0.447715 19.5523 0 19 0H5ZM6 2H18V20H6V2ZM13 22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22C11 21.4477 11.4477 21 12 21C12.5523 21 13 21.4477 13 22Z" />
    </SvgIcon>
  );
};

export default Phone;
