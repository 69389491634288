import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import Logo from "./logo";

type Props = {
  title: string;
};

const HeaderLogo = styled(Logo)(({ theme }) => ({ height: "3rem", width: "3rem", fill: theme.palette.primary.main }));

const Header: React.FC<Props> = ({ title }) => {
  const [t] = useTranslation();

  return (
    <AppBar position="relative">
      <Toolbar sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h1">{title}</Typography>
        <HeaderLogo title={t("aria.logo")}></HeaderLogo>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
