import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../formatters";
import Arrow from "./arrow";
import Link from "./link";

type Props = {
  credits: LedgerLineItem;
  debits: LedgerLineItem[];
  disbursementFlag?: React.ReactNode;
  disbursementDetail?: React.ReactNode;
};

export type LedgerLineItem = {
  name: string;
  amount: number;
};

const LedgerItem = styled(TableRow)(({ theme }) => ({
  "& .MuiTableCell-root": {
    "font-weight": 500,
    color: theme.palette.grey[900],
  },

  "&.total": {
    backgroundColor: theme.palette.grey[100],
    "& .MuiTableCell-root": {
      paddingTop: "0.5rem",
      paddingBottom: "1rem",
      border: "none",
      fontSize: "1rem",
    },
  },
  "&.disbursement": {
    backgroundColor: theme.palette.grey[100],
    "& .MuiTableCell-root": {
      paddingTop: "0.5rem",
      paddingRight: "0.5rem",
      paddingLeft: "0.5rem",
      paddingBottom: "1rem",

      "& .MuiTypography-root": {
        color: theme.palette.grey[900],
      },
    },
  },
}));

const NameCell = styled(TableCell)({ padding: "1rem 0rem" });
const AmountCell = styled(TableCell)({ padding: "1rem 0rem" });

const SubLedgerItem = styled(TableRow)(({ theme }) => ({
  "& .MuiTableCell-root": {
    color: theme.palette.grey[600],
  },
}));

const Ledger: React.FC<Props> = ({ credits, debits, disbursementFlag, disbursementDetail }) => {
  const [showDebits, setShowDebits] = useState(false);
  const totalDebits = useMemo(() => debits.reduce((sum, { amount }) => sum + amount, 0), [debits]);
  const total = useMemo(() => credits.amount - totalDebits, [credits, totalDebits]);
  const [t] = useTranslation();

  const toggleShowDebits = useCallback(() => {
    setShowDebits(!showDebits);
  }, [showDebits]);

  return (
    <Table>
      <TableBody>
        <LedgerItem data-testid={"credit-0"}>
          <NameCell colSpan={2}>{credits.name}</NameCell>
          <AmountCell align="right">{formatCurrency(credits.amount)}</AmountCell>
        </LedgerItem>
        <LedgerItem data-testid={`fee-summary`}>
          <NameCell colSpan={2}>
            <Link
              data-testid={`fee-toggle`}
              component="button"
              variant="body2"
              color="secondary"
              aria-expanded={showDebits}
              aria-controls={`fee-details`}
              onClick={toggleShowDebits}
              endIcon={<Arrow sx={{ verticalAlign: "middle" }} fontSize="small" expand={showDebits} />}
            >
              {t("fees")}
            </Link>
          </NameCell>

          <AmountCell align="right">{formatCurrency(-1 * totalDebits)}</AmountCell>
        </LedgerItem>
        {showDebits &&
          debits.map((debit, index) => (
            <SubLedgerItem key={`debit-${index}`} data-testid={`debit-${index}`}>
              {index === 0 && <TableCell rowSpan={debits.length}></TableCell>}
              <NameCell>{debit.name}</NameCell>
              <AmountCell align="right">{formatCurrency(-1 * debit.amount)}</AmountCell>
            </SubLedgerItem>
          ))}
        <LedgerItem className={"total"} data-testid={`total`}>
          <NameCell colSpan={2}>{disbursementFlag}</NameCell>
          <AmountCell align="right">{formatCurrency(total)}</AmountCell>
        </LedgerItem>
        {disbursementDetail && (
          <LedgerItem className={"disbursement"} data-testid={`disbursement`}>
            <NameCell colSpan={3}>{disbursementDetail}</NameCell>
          </LedgerItem>
        )}
      </TableBody>
    </Table>
  );
};

export default Ledger;
