export const green = "#14aa40";
export const darkGreen = "#005d1f";
export const blue = "#3bc0f8";
export const red = "#CE2B1E";
export const yellow = "#F5CC02";

export const white = "#ffffff";
export const offWhite = "#F1F2F2";
export const mediumWhite = "#F1F5F6";
export const greyBlue = "#E6ECEF";
export const lightGrey = "#E4E4E4";
export const mediumGrey = "#A6A6A6";
export const websiteLightGrey = "#6E6E6E";
export const unselectedText = "#5B5F68";
export const darkGrey = "#262626";
export const offBlack = "#202020";
export const black = "#000000";
