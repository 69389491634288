import { SvgIconProps } from "@mui/material/SvgIcon";
import ChevronDown from "icons/ChevronDown";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = SvgIconProps & {
  expand: boolean;
};

const Arrow: React.FC<Props> = ({ expand, className, sx, ...props }) => {
  const [t] = useTranslation();

  return (
    <ChevronDown
      sx={{ ...sx, transform: expand ? "scaleY(-1)" : undefined }}
      {...props}
      titleAccess={expand ? t("aria.expandIcon") : t("aria.collapseIcon")}
    />
  );
};

export default Arrow;
