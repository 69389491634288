import React, { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { RefundMessage, Refund } from "data/refund";
import Link from "components/link";
import { useContactInfo } from "./useContactInfo";

export const useDisbursementInfo = () => {
  const [t] = useTranslation("messages");
  const getContactInfo = useContactInfo();
  return useCallback(
    (refund: Refund) => {
      const contactInfo = getContactInfo(refund);
      switch (refund.messageNumber) {
        case RefundMessage.StateReceivedAfterFedLowerAmount:
          return (
            <Trans i18nKey={`messages:${RefundMessage.StateReceivedAfterFedLowerAmount}.moreDetails`}>
              Click
              <Link
                aria-label={t("translation:aria.stateLink", { name: contactInfo.name })}
                href={contactInfo.web}
                target="_blank"
                rel="noopener"
                color="secondary"
                variant="inherit"
                data-testid="contact-website"
              >
                here
              </Link>
              to visit the website for the state of{{ name: contactInfo.name }} for their specific refund guidelines.
            </Trans>
          );
        case RefundMessage.StatePastAnticipatedDate:
          return (
            <Trans i18nKey={`messages:${RefundMessage.StatePastAnticipatedDate}.moreDetails`}>
              Click
              <Link
                aria-label={t("translation:aria.stateLink", { name: contactInfo.name })}
                href={contactInfo.web}
                target="_blank"
                rel="noopener"
                color="secondary"
                data-testid="contact-website"
              >
                here
              </Link>
              to visit the website for the state of{{ name: contactInfo.name }} for their specific refund guidelines.
            </Trans>
          );
        case RefundMessage.StatePendingAnticipatedDate:
          return (
            <Trans i18nKey={`messages:${RefundMessage.StatePendingAnticipatedDate}.moreDetails`}>
              Refund timing varies by state. Click
              <Link
                aria-label={t("translation:aria.stateLink", { name: contactInfo.name })}
                href={contactInfo.web}
                target="_blank"
                rel="noopener"
                color="secondary"
                data-testid="contact-website"
              >
                here
              </Link>
              to visit the website for the state of{{ name: contactInfo.name }} for their specific refund guidelines.
            </Trans>
          );
        case RefundMessage.StateFlipToCheck:
          return (
            <Trans i18nKey={`messages:${RefundMessage.StateFlipToCheck}.moreDetails`}>
              For additional information, you may contact us at 1-800-HRBLOCK (
              <Link
                href={`tel:${contactInfo.phone}`}
                color="secondary"
                variant="inherit"
                data-testid="contact-phonenumber"
              >
                {{ phone: contactInfo.phone }}
              </Link>
            </Trans>
          );
        case RefundMessage.StateReceivedBeforeFedLowerAmount:
          return (
            <Trans i18nKey={`messages:${RefundMessage.StateReceivedAfterFedLowerAmount}.moreDetails`}>
              Click
              <Link
                aria-label={t("translation:aria.stateLink", { name: contactInfo.name })}
                href={contactInfo.web}
                target="_blank"
                rel="noopener"
                color="secondary"
                variant="inherit"
                data-testid="contact-website"
              >
                here
              </Link>
              to visit the website for the state of{{ name: contactInfo.name }} for their specific refund guidelines.
            </Trans>
          );
        case RefundMessage.FederalReceivedLowerAmount:
          return (
            <Trans i18nKey={`messages:${RefundMessage.FederalReceivedLowerAmount}.moreDetails`}>
              For questions on why your refund was reduced by the IRS, please contact the IRS at
              <Link
                href={`tel:${contactInfo.phone}`}
                color="secondary"
                variant="inherit"
                data-testid="contact-phonenumber"
              >
                {{ phone: contactInfo.phone }}
              </Link>
              or click
              <Link
                aria-label={t("translation:aria.fedLink", { name: contactInfo.abv })}
                href={contactInfo.web}
                target="_blank"
                rel="noopener"
                color="secondary"
                data-testid="contact-website"
              >
                here
              </Link>
              to visit their website.
            </Trans>
          );
        case RefundMessage.FederalPastAnticipatedDate:
          return (
            <Trans i18nKey={`messages:${RefundMessage.FederalPastAnticipatedDate}.moreDetails`}>
              The IRS typically processes most refunds within 21 days of your return being e-file accepted. Since this
              expected timeframe has passed, you may call IRS at
              <Link
                href={`tel:${contactInfo.phone}`}
                color="secondary"
                variant="inherit"
                data-testid="contact-phonenumber"
              >
                {{ phone: contactInfo.phone }}
              </Link>
              or click
              <Link
                aria-label={t("translation:aria.fedLink", { name: contactInfo.abv })}
                href={contactInfo.web}
                target="_blank"
                rel="noopener"
                color="secondary"
                data-testid="contact-website"
              >
                here
              </Link>
              to visit their website.
            </Trans>
          );
        case RefundMessage.FederalPendingAnticipatedDate:
          return (
            <Trans i18nKey={`messages:${RefundMessage.FederalPendingAnticipatedDate}.moreDetails`}>
              The IRS typically processes most refunds within 21 days of your return being e-file accepted. Since this
              expected timeframe has passed, you may call IRS at
              <Link
                href={`tel:${contactInfo.phone}`}
                color="secondary"
                variant="inherit"
                data-testid="contact-phonenumber"
              >
                {{ phone: contactInfo.phone }}
              </Link>
              or click
              <Link
                aria-label={t("translation:aria.fedLink", { name: contactInfo.abv })}
                href={contactInfo.web}
                target="_blank"
                rel="noopener"
                color="secondary"
                data-testid="contact-website"
              >
                here
              </Link>
              to visit their website.
            </Trans>
          );
        case RefundMessage.FederalPendingAnticipatedDatePathAct:
          return (
            <Trans
              i18nKey={`messages:${RefundMessage.FederalPendingAnticipatedDatePathAct}.moreDetails`}
              values={refund}
            >
              The IRS will have the most up-to-date information on your refund. For details, you may contact the IRS by
              clicking
              <Link
                aria-label={t("translation:aria.fedLink", { name: contactInfo.abv })}
                href={contactInfo.web}
                target="_blank"
                rel="noopener"
                color="secondary"
                data-testid="contact-website"
              >
                here
              </Link>
              to visit their website.
            </Trans>
          );
        case RefundMessage.FederalFlipToCheck:
          return (
            <Trans i18nKey={`messages:${RefundMessage.FederalFlipToCheck}.moreDetails`}>
              For additional information, you may contact us at 1-800-HRBLOCK (
              <Link
                href={`tel:${contactInfo.phone}`}
                color="secondary"
                variant="inherit"
                data-testid="contact-phonenumber"
              >
                {{ phone: contactInfo.phone }}
              </Link>
            </Trans>
          );
        case RefundMessage.FederalPastAnticipatedDatePathAct:
          return (
            <Trans i18nKey={`messages:${RefundMessage.FederalPastAnticipatedDatePathAct}.moreDetails`} values={refund}>
              The IRS will have the most up-to-date information on your refund. For details, you may contact the IRS by
              clicking
              <Link
                aria-label={t("translation:aria.fedLink", { name: contactInfo.abv })}
                href={contactInfo.web}
                target="_blank"
                rel="noopener"
                color="secondary"
                data-testid="contact-website"
              >
                here
              </Link>
              to visit their website.
            </Trans>
          );
        default:
          return t(`${refund.messageNumber}.moreDetails`, refund);
      }
    },
    [t, getContactInfo],
  );
};
