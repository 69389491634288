import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const HrbHourglass: React.FC<SvgIconProps> = ({ titleAccess, ...props }) => {
  return (
    <SvgIcon {...props} aria-label={titleAccess} titleAccess={titleAccess}>
      <path d="M4,2 L4,4 L6,4 L6,9.414 L9,12.414 L9,12.586 L6,15.585 L6,21 L4,21 L4,23 L20,23 L20,21 L18,21 L18,15.585 L15,12.586 L15,12.414 L18,9.414 L18,4 L20,4 L20,2 L4,2 Z M8,16.413 L11,13.414 L11,11.586 L8,8.585 L8,4 L16,4 L16,8.585 L13,11.586 L13,13.414 L16,16.413 L16,21 L8,21 L8,16.413 Z" />
    </SvgIcon>
  );
};

export default HrbHourglass;
