import { reducerWithInitialState } from "typescript-fsa-reducers";

import { getRefundByToken } from "./actions";
import { RefundState } from "./types";

export const initialState: RefundState = {
  loading: true,
  refund: undefined,
  error: undefined,
};

const reducer = reducerWithInitialState(initialState)
  .case(getRefundByToken.started, () => ({
    ...initialState,
    loading: true,
  }))
  .case(getRefundByToken.done, (state, { result: refund }) => ({
    ...state,
    refund,
    loading: false,
  }))
  .case(getRefundByToken.failed, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  }));

export default reducer;
