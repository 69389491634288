import React, { useMemo } from "react";
import { useRefund } from "data/refund";
import StepList, { StepItem } from "components/steplist";
import { useAnticipatedRefundStep } from "./useRefundStep";

const AnticipatedSteps: React.FC = () => {
  const [{ refund }] = useRefund();
  const getStep = useAnticipatedRefundStep();
  const anticipatedSteps = useMemo(
    () => [getStep(refund!, "state"), getStep(refund!, "federal")].filter((step) => step !== null) as StepItem[],
    [getStep, refund],
  );

  return <StepList list={anticipatedSteps} showConnector={false} />;
};

export default AnticipatedSteps;
