import { useCallback } from "react";
import { useContact, HRBlock, Federal } from "data/contact";
import { Refund, RefundMessage, StateCode } from "data/refund";

const getContactCode = (refund: Refund): HRBlock | Federal | StateCode => {
  if (
    refund.messageNumber === RefundMessage.StateFlipToCheck ||
    refund.messageNumber === RefundMessage.FederalFlipToCheck
  ) {
    return "HRBlock";
  } else if (refund.stateCode) {
    return refund.stateCode;
  } else {
    return "federal";
  }
};

export const useContactInfo = () => {
  const getContact = useContact();
  return useCallback((refund: Refund) => getContact(getContactCode(refund)), [getContact]);
};
