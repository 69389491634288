import React, { useMemo } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";

type Props = {
  children?: React.ReactNode;
};

const DocumentLanguage: React.FC<Props> = ({ children }) => {
  const [, i18n] = useTranslation();
  const language = useMemo(() => i18n.language, [i18n.language]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: language }} />
      {children}
    </>
  );
};

export default DocumentLanguage;
