import DocumentTitle from "components/documentTitle";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import NotFound from "./errors/notFound";
import UnhandledError from "./errors/unhandledError";
import RefundIndexRoute from "./refund";
import { routes } from "./routes";

const App: React.FC = () => {
  const [t] = useTranslation();

  return (
    <DocumentTitle title={t("app.title")}>
      <ErrorBoundary FallbackComponent={UnhandledError}>
        <Router>
          <Routes>
            <Route path={routes.errors.notFound.path} element={<NotFound />} />
            <Route path={routes.refund.path} element={<RefundIndexRoute />} />
            <Route path="*" element={<Navigate to={routes.errors.notFound.path} />} />
          </Routes>
        </Router>
      </ErrorBoundary>
    </DocumentTitle>
  );
};

export default App;
