import Header from "components/header";
import LastUpdateBanner from "components/lastUpdateBanner";
import LoadingScreen from "components/loadingScreen";
import Wrapper from "components/wrapper";
import { RefundProvider, useRefund } from "data/refund";
import { asDate } from "formatters";
import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router";
import { routes } from "ui/routes";
import AnticipatedSteps from "./components/anticipatedSteps";
import CompletedSteps from "./components/completedSteps";

const Tracker: React.FC = () => {
  const [t] = useTranslation();
  const [{ loading, refund }] = useRefund();

  return (
    <>
      {!loading && !refund && <Navigate to={routes.errors.notFound.path} />}
      <Wrapper in={Boolean(refund)} loading={loading && <LoadingScreen />} timeout={750}>
        <>
          <Header title={t("welcome")}></Header>
          {refund && (
            <LastUpdateBanner
              sx={{ margin: "0.75rem 1rem" }}
              lastUpdate={refund.updatedDate ? asDate(refund.updatedDate) : undefined}
            />
          )}
          {refund && <CompletedSteps />}
          {refund && <AnticipatedSteps />}
        </>
      </Wrapper>
    </>
  );
};

export default () => {
  const { token } = useParams<{ token?: string }>();
  return (
    <RefundProvider token={token!}>
      <Tracker />
    </RefundProvider>
  );
};
