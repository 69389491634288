import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { StateCode } from "data/refund";

export type HRBlock = "HRBlock";
export type Federal = "federal";

export const useContact = () => {
  const [t] = useTranslation("contactInfo");
  return useCallback(
    (contactCode: HRBlock | Federal | StateCode = "HRBlock") => {
      return {
        name: t(`${contactCode}.name`),
        phone: t(`${contactCode}.phone`),
        web: t(`${contactCode}.web`),
        abv: t(`${contactCode}.abv`),
      };
    },
    [t],
  );
};
