import Container from "@mui/material/Container";
import Fade, { FadeProps } from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Footer from "components/footer";
import React from "react";

type Props = FadeProps & {
  loading?: React.ReactNode;
  children?: React.ReactNode;
};

const BodyBackground = styled("div")(({ theme }) => ({
  width: "100%",
  paddingBottom: "2rem",
  backgroundColor: theme.palette.common.white,
}));

const Wrapper: React.FC<Props> = ({ children, loading, ...fadeProps }) => {
  return (
    <Container
      maxWidth="sm"
      sx={{ "&&": { display: "flex", flexDirection: "column", minHeight: "100vh", padding: "0" } }}
    >
      <Grid
        item
        xs={12}
        sx={{
          flex: "1 1 auto",
          display: "flex",
          "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)": {
            minHeight: "100vh",
          },
        }}
      >
        {loading}
        <Fade {...fadeProps}>
          <BodyBackground>{children}</BodyBackground>
        </Fade>
      </Grid>
      <Grid item xs={12} sx={{ flex: "0 0 auto" }}>
        <Fade {...fadeProps}>
          <div>
            <Footer />
          </div>
        </Fade>
      </Grid>
    </Container>
  );
};

export default Wrapper;
