import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import { formatCurrency, formatDate, formatPhoneNumber } from "./formatters";

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    supportedLngs: ["en"],
    nonExplicitSupportedLngs: true,
    fallbackLng: "en",
    debug: true,
    ns: ["translation", "messages", "fees", "contactInfo"],
    defaultNS: "translation",

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function (value, format, lng) {
        if (format === "currency") return formatCurrency(value, lng);
        if (format === "tel") return formatPhoneNumber(value, lng);
        if (format) return formatDate(value, format, lng);

        return value;
      },
    },

    detection: {
      order: ["querystring", "navigator", "htmlTag"],
    },

    backend: {
      queryStringParams: {
        ...(process.env.REACT_APP_VERSION ? { v: process.env.REACT_APP_VERSION } : {}),
      },
    },
  });

export default i18n;
