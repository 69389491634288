import { useCallback, useReducer } from "react";
import * as actions from "./actions";
import reducer, { initialState } from "./reducer";
import { RefundState, RefundStatus } from "./types";

export type RefundByToken = [RefundState, (token: string) => Promise<void>];

export default function useRefundByToken(
  baseUrl: string = process.env.REACT_APP_S3_BASE_URL || "/data/",
): RefundByToken {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fn = useCallback(
    async (token: string) => {
      dispatch(actions.getRefundByToken.started());
      try {
        const response = await fetch(`${baseUrl}${token}`, { cache: "no-store" });
        switch (response.status) {
          case 200: {
            const refundStatus = (await response.json()) as RefundStatus;
            dispatch(actions.getRefundByToken.done({ result: refundStatus }));
            break;
          }
          case 404:
            dispatch(actions.getRefundByToken.done({ result: null }));
            break;
          default:
            throw Error(response.statusText);
        }
      } catch (error) {
        dispatch(actions.getRefundByToken.failed({ error }));
      }
    },
    [baseUrl, dispatch],
  );
  return [state, fn];
}
