import HourglassIcon from "icons/Hourglass";
import React from "react";
import { useTranslation } from "react-i18next";
import IconBubble, { IconBubbleProps } from "./iconBubble";

type Props = Partial<IconBubbleProps>;

const Hourglass: React.FC<Props> = ({ color = "secondary" }) => {
  const [t] = useTranslation();

  return (
    <IconBubble color={color}>
      <HourglassIcon titleAccess={t("aria.hourglassIcon")}></HourglassIcon>
    </IconBubble>
  );
};

export default Hourglass;
