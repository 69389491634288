import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import DocumentLanguage from "components/documentLanguage";
import React from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { theme } from "./styles";
import App from "./ui";

const Loading: React.FC = () => {
  return <div className={"loading-backdrop"}></div>;
};

const Root: React.FC = () => {
  return (
    <React.Suspense fallback={<Loading />}>
      <I18nextProvider i18n={i18n}>
        <DocumentLanguage>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <App></App>
            </ThemeProvider>
          </StyledEngineProvider>
        </DocumentLanguage>
      </I18nextProvider>
    </React.Suspense>
  );
};

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(<Root />);
