import Typography from "@mui/material/Typography";
import { DisbursementType } from "data/refund";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  type: DisbursementType;
};

const useDisbursementMessage = () => {
  const [t] = useTranslation();
  return useCallback(
    (type: DisbursementType) => {
      switch (type) {
        case "check":
          return t("checkMessage");
        case "direct-deposit":
          return t("directDepositMessage");
        case "emerald-card":
          return t("emeraldCardMessage");
        case "spruce-card":
          return t("spruceCardMessage");
        case "none":
          return t("noDisbursementMessage");
      }
    },
    [t],
  );
};

const DisbursementMessage: React.FC<Props> = ({ type }) => {
  const getDisbursementMessage = useDisbursementMessage();
  return <Typography variant="body2">{getDisbursementMessage(type)}</Typography>;
};

export default DisbursementMessage;
