import React from "react";
import { ReactComponent as LogoSvg } from "./hr_block_square.svg";

type Props = {
  className?: string;
  title: string;
};
const Logo: React.FC<Props> = (props: Props) => {
  return <LogoSvg {...props}></LogoSvg>;
};

export default Logo;
