export const formatPhoneNumber = (phone: string, locale = "en") => {
  const number = phone.replace(/\D/g, "");
  switch (number.length) {
    case 7: {
      const match = number.match(/^(\d{3})(\d{4})$/);
      return match ? [match[1], match[2]].join("-") : "";
    }
    case 10: {
      const match = number.match(/^(\d{3})(\d{3})(\d{4})$/);
      return match ? [match[1], match[2], match[3]].join("-") : "";
    }
    case 11: {
      const match = number.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      const intlCode = match && match[1] ? "1" : "";
      return match ? [intlCode, match[2], match[3], match[4]].join("-") : "";
    }
    default:
      return "";
  }
};
