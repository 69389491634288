import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ChevronDown: React.FC<SvgIconProps> = ({ titleAccess, ...props }) => {
  return (
    <SvgIcon {...props} aria-label={titleAccess} titleAccess={titleAccess}>
      <polygon
        transform="translate(20,4.5) rotate(-270)"
        points="5.33333333 14.3076923 4 13.0769231 9.33238095 8.15384615 4 3.23076923 5.33333333 2 12 8.15384615"
      ></polygon>
    </SvgIcon>
  );
};

export default ChevronDown;
