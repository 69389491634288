import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import Logo from "./logo";

const LoadingScreenLogo = styled(Logo)(({ theme }) => ({
  height: "3rem",
  width: "3rem",
  fill: theme.palette.primary.main,
  margin: "1rem",
}));

type Props = {
  style?: React.CSSProperties;
};

const LoadingScreen: React.FC<Props> = (props) => {
  const [t] = useTranslation();
  return (
    <div className={"loading-backdrop"} {...props}>
      <Typography
        variant="h5"
        sx={(theme) => ({ fontWeight: "bold", fontStyle: "normal", margin: "1rem", color: theme.palette.common.white })}
      >
        {t("loading")}
      </Typography>
      <LoadingScreenLogo title={t("aria.logo")}></LoadingScreenLogo>
    </div>
  );
};

export default LoadingScreen;
