import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";
import { Trans } from "react-i18next";
import { medium } from "styles/fonts";

const FooterLink = styled(Link)(({ theme }) => ({
  cursor: "pointer",
  textTransform: "uppercase",
  "&:hover": {
    color: theme.palette.common.black,
  },
}));

const DefaultFooter: React.FC = (props) => {
  return (
    <Grid sx={(theme) => ({ padding: "1rem", backgroundColor: theme.palette.grey[100] })} {...props}>
      <Typography
        sx={(theme) => ({
          color: theme.palette.grey[700],
          fontSize: "0.6875rem",
          fontWeight: medium,
          lineHeight: 1.625,
        })}
        variant="body2"
      >
        Copyright &copy; {new Date().getFullYear().toString()} HRB Digital LLC. All Rights Reserved.
      </Typography>
      <Typography
        sx={(theme) => ({ color: theme.palette.grey[700], fontSize: "0.75rem", fontWeight: medium, lineHeight: 1.625 })}
        variant="body2"
      >
        <Trans i18nKey={"privacyPolicy"}>
          See
          <FooterLink
            data-testid={"terms-of-use"}
            underline="always"
            href="https://www.hrblock.com/universal/terms.html"
            color="inherit"
            target="_blank"
            rel="noopener"
          >
            Terms of Use
          </FooterLink>
          and
          <FooterLink
            data-testid={"privacy-policy"}
            underline="always"
            href="https://www.hrblock.com/universal/digital-online-mobile-privacy-principles.html"
            color="inherit"
            target="_blank"
            rel="noopener"
          >
            Privacy Policy
          </FooterLink>
          at
          <FooterLink
            data-testid={"hrblock"}
            underline="always"
            href="https://www.hrblock.com"
            color="inherit"
            target="_blank"
            rel="noopener"
          >
            hrblock.com
          </FooterLink>
          hrblock.com
        </Trans>
      </Typography>
    </Grid>
  );
};

export default DefaultFooter;
